// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/***** Color list *****/
/*****Media Queries*****/
.CardServiceLink {
  position: relative;
}
.CardServiceLink__overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.CardServiceLink__title {
  font-family: Lato Bold;
  color: white;
  font-size: 35px;
  text-transform: uppercase;
  text-shadow: 4px 4px 3px rgba(0, 0, 0, 0.5);
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/styles/utils/variables.scss","webpack://./src/styles/utils/mixins.scss","webpack://./src/components/CardServiceLink/style.scss"],"names":[],"mappings":"AAAA,uBAAA;ACAA,wBAAA;ACGA;EACI,kBAAA;AAAJ;AACI;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,oCAAA;EACA,UAAA;AACR;AACI;EACI,sBAAA;EACA,YAAA;EACA,eAAA;EACA,yBAAA;EACA,2CAAA;EACA,UAAA;AACR","sourcesContent":["/***** Color list *****/\n\n$primary: var(--primary);\n$secondary: var(--secondary);\n$tertiary: var(--tertiary);","/*****Media Queries*****/\n\n@mixin Small {\n    @media (min-width: 576px) {\n        @content;\n    }\n}\n\n@mixin Medium {\n    @media (min-width: 768px) {\n        @content;\n    }\n}\n\n@mixin Large {\n    @media (min-width: 992px) {\n        @content;\n    }\n}\n\n@mixin Extra-large {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin Extra-extra-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}\n","@import \"../../styles/utils/variables.scss\";\n@import \"../../styles/utils/mixins.scss\";\n\n.CardServiceLink {\n    position: relative;\n    &__overlay {\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        align-items: center;\n        width: 100%;\n        height: 150px;\n        background-color: rgba(0, 0, 0, 0.3);\n        z-index: 1;\n    }\n    &__title {\n        font-family: Lato Bold;\n        color: white;\n        font-size: 35px;\n        text-transform: uppercase;\n        text-shadow: 4px 4px 3px rgba(0, 0, 0, 0.5);\n        z-index: 2;\n    }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
