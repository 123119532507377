// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/***** Color list *****/
/*****Media Queries*****/
.marquee {
  display: flex;
  width: 300%;
  height: 100%;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 30s linear infinite;
}
.marquee__content {
  display: inline-block;
  padding-right: 0;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.marquee__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Aligne le contenu en bas */
  height: 100%;
}
@media (min-width: 576px) {
  .marquee__content {
    justify-content: flex-end;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/utils/variables.scss","webpack://./src/styles/utils/mixins.scss","webpack://./src/components/Marquee/style.scss"],"names":[],"mappings":"AAAA,uBAAA;ACAA,wBAAA;ACGA;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;EACA,sCAAA;AAAJ;AACI;EACI,qBAAA;EACA,gBAAA;AACR;;AAGA;EACI;IACI,wBAAA;EAAN;EAEE;IACI,2BAAA;EAAN;AACF;AAGA;EACI,aAAA;EACA,sBAAA;EACA,yBAAA,EAAA,6BAAA;EACA,YAAA;AADJ;ADzBI;ECsBJ;IAMQ,yBAAA;EACN;AACF","sourcesContent":["/***** Color list *****/\n\n$primary: var(--primary);\n$secondary: var(--secondary);\n$tertiary: var(--tertiary);","/*****Media Queries*****/\n\n@mixin Small {\n    @media (min-width: 576px) {\n        @content;\n    }\n}\n\n@mixin Medium {\n    @media (min-width: 768px) {\n        @content;\n    }\n}\n\n@mixin Large {\n    @media (min-width: 992px) {\n        @content;\n    }\n}\n\n@mixin Extra-large {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin Extra-extra-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}\n","@import \"../../styles/utils/variables.scss\";\n@import \"../../styles/utils/mixins.scss\";\n\n.marquee {\n    display: flex;\n    width: 300%;\n    height: 100%;\n    white-space: nowrap;\n    will-change: transform;\n    animation: marquee 30s linear infinite;\n    &__content {\n        display: inline-block;\n        padding-right: 0;\n    }\n}\n\n@keyframes marquee {\n    from {\n        transform: translateX(0);\n    }\n    to {\n        transform: translateX(-50%);\n    }\n}\n\n.marquee__content {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end; /* Aligne le contenu en bas */\n    height: 100%;\n    @include Small {\n        justify-content: flex-end;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
