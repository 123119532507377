// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/***** Color list *****/
/*****Media Queries*****/
.insta__card {
  max-width: 400px;
  margin: 0 auto;
}

.insta__card .card-img-top {
  width: 200px; /* Assure-toi que l'image ou la vidéo occupe toute la largeur de la carte */
  height: 200px; /* Ajuste la hauteur selon tes besoins */
  object-fit: cover; /* Fait en sorte que l'image/vidéo remplisse son conteneur sans déformation */
}

/* Optionnel : Ajuste la hauteur ou le comportement spécifique pour les vidéos */
.insta-media {
  object-fit: cover; /* Comme pour les images, assure que la vidéo s'ajuste bien */
  border: 12px solid var(--secondary);
  border-radius: 25px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Accueil/style.scss","webpack://./src/styles/utils/variables.scss","webpack://./src/styles/utils/mixins.scss"],"names":[],"mappings":"AAAA,gBAAgB;ACAhB,uBAAA;ACAA,wBAAA;AFGA;EACI,gBAAA;EACA,cAAA;AACJ;;AAEA;EACI,YAAA,EAAA,2EAAA;EACA,aAAA,EAAA,wCAAA;EACA,iBAAA,EAAA,6EAAA;AACJ;;AAEA,gFAAA;AACA;EACI,iBAAA,EAAA,6DAAA;EACA,mCAAA;EACA,mBAAA;AACJ","sourcesContent":["@import \"../../styles/utils/variables.scss\";\n@import \"../../styles/utils/mixins.scss\";\n\n.insta__card {\n    max-width: 400px;\n    margin: 0 auto;\n}\n\n.insta__card .card-img-top {\n    width: 200px; /* Assure-toi que l'image ou la vidéo occupe toute la largeur de la carte */\n    height: 200px; /* Ajuste la hauteur selon tes besoins */\n    object-fit: cover; /* Fait en sorte que l'image/vidéo remplisse son conteneur sans déformation */\n}\n\n/* Optionnel : Ajuste la hauteur ou le comportement spécifique pour les vidéos */\n.insta-media {\n    object-fit: cover; /* Comme pour les images, assure que la vidéo s'ajuste bien */\n    border: 12px solid var(--secondary);\n    border-radius: 25px;\n}\n","/***** Color list *****/\n\n$primary: var(--primary);\n$secondary: var(--secondary);\n$tertiary: var(--tertiary);","/*****Media Queries*****/\n\n@mixin Small {\n    @media (min-width: 576px) {\n        @content;\n    }\n}\n\n@mixin Medium {\n    @media (min-width: 768px) {\n        @content;\n    }\n}\n\n@mixin Large {\n    @media (min-width: 992px) {\n        @content;\n    }\n}\n\n@mixin Extra-large {\n    @media (min-width: 1200px) {\n        @content;\n    }\n}\n\n@mixin Extra-extra-large {\n    @media (min-width: 1400px) {\n        @content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
